import { createGetKcContext } from 'keycloakify/login'

export type KcContextExtension =
  // WARNING: It's important to keep in sync the extraThemeProperties declared in the package.json and this type definition.
  | { extraThemeProperties: Record<string, string>; pageId: 'login.ftl' }
  // NOTE: register.ftl is deprecated in favor of register-user-profile.ftl
  // but let's say we use it anyway and have this plugin enabled: https://github.com/micedre/keycloak-mail-whitelisting
  // keycloak-mail-whitelisting define the non standard ftl global authorizedMailDomains, we declare it here.
  | { authorizedMailDomains: string[]; pageId: 'register.ftl' }

export const mockSocialProviders = [
  {
    alias: 'google',
    displayName: 'Google',
    loginUrl:
      '/auth/kc/realms/global/broker/google/login?client_id=auth&tab_id=gf5kwtn6h3Y&session_code=zzDsaYm8BzLoYUkMlAwn2Xn56hO_OSMu6XV5iCKcwng',
    providerId: 'google',
  },
  {
    alias: 'microsoft',
    displayName: 'Microsoft',
    loginUrl:
      '/realms/myrealm/broker/microsoft/login?client_id=auth&tab_id=m0MdtWwPFqk&session_code=CWR-vYad6rr9xvwtcGicZmRkTWqCelsrYdcpezKxB4U',
    providerId: 'microsoft',
  },
]

export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: 'login.ftl',
      locale: {
        //When we test the login page we do it in french
        currentLanguageTag: 'en',
      },
      social: {
        providers: mockSocialProviders,
      },
      // It should be in mockProperties next to mockData according to the docs
      // https://docs.keycloakify.dev/v/v9/environment-variables
      // But it doesn't work, so put it here
      // @ts-ignore mocking
      properties: {
        REACT_APP_ROLLOUT_KEY: 'MOCKED_ROLLOUT_KEY',
      },
    },
    {
      pageId: 'register.ftl',
      authorizedMailDomains: [
        'example.com',
        'another-example.com',
        '*.yet-another-example.com',
        '*.example.com',
        'hello-world.com',
      ],
    },
    {
      //NOTE: You will either use register.ftl (legacy) or register-user-profile.ftl, not both
      pageId: 'register-user-profile.ftl',
      locale: {
        currentLanguageTag: 'fr',
      },
      profile: {
        attributes: [
          {
            validators: {
              pattern: {
                pattern: '^[a-zA-Z0-9]+$',
                'ignore.empty.value': true,
                // eslint-disable-next-line no-template-curly-in-string
                'error-message': '${alphanumericalCharsOnly}',
              },
            },
            //NOTE: To override the default mock value
            value: undefined,
            name: 'username',
          },
          {
            validators: {
              options: {
                options: [
                  'male',
                  'female',
                  'non-binary',
                  'transgender',
                  'intersex',
                  'non_communicated',
                ],
              },
            },
            // eslint-disable-next-line no-template-curly-in-string
            displayName: '${gender}',
            annotations: {},
            required: true,
            groupAnnotations: {},
            readOnly: false,
            name: 'gender',
          },
        ],
      },
    },
  ],
})

export const { kcContext } = getKcContext({
  // Uncomment to test the login page for development.
  // mockPageId: 'login.ftl',
})

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>

export interface KcSocialProvider {
  alias: string
  displayName: string
  loginUrl: string
  providerId: string
}
